import { FC, ReactNode, memo } from "react";

import { WithDataAttr } from "apps/website/types";
import cx from "apps/website/utils/misc/cx";

import { TBodyTag } from "../types";

import textBodyStyles, { TextBodyVariantsProps } from "./styles";

export interface IBodyProps extends WithDataAttr, TextBodyVariantsProps {
  id?: string;
  tag?: TBodyTag
  children: ReactNode;
  className?: string;
}

const TextBody: FC<IBodyProps> = ({
  id,
  tag: Tag = "p",
  children,
  "data-testid": testId,
  size,
  align,
  whitespace,
  color,
  weight,
  className,
}) => (
  <Tag
    id={id}
    data-testid={testId}
    className={ cx(
      textBodyStyles({ size, align, whitespace, color, weight }),
      className,
      Tag === "p" ? "[&>a]:font-bold [&>a]:underline" : "",
    )}
  >
    { children }
  </Tag>
);

export default memo(TextBody);
